import gstrTwo from './gstrTwo';
import gstrTwoB from './gstrTwoB';
import gstrThreeB from './gstrThreeB';
import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import { required } from 'vuelidate/lib/validators';
import commonHelper from '@/app/utility/common.helper.utility';
import itcSummaryReport from '../gstrTwoA/itcSummaryReport';
export default {
  name: 'complainceDashboard',
  components: { gstrTwo, gstrTwoB, gstrThreeB, ModelSelect, itcSummaryReport },
  watch: {
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.mainSearch();
    }
  },
  data() {
    return {
      loader: false,
      activeTab: 'gstrTwo',
      leName: {
        value: null,
        text: null
      },
      showValueSetModal: false,
      defaultValue: {
        value: null,
        text: null
      },
      selectedGSTNumber: {
        value: null,
        text: null
      },
      selectedItcEligible: {
        value: null,
        text: null
      },
      selectedReverseCharge: {
        value: 'N',
        text: 'No'
      },
      selectedPeriodFrom: {
        value: null,
        text: null
      },
      selectedPeriodTo: {
        value: null,
        text: null
      },
      selectedPeriod: {
        value: null,
        text: null
      },
      periodType: null,
      searchParams: {},
      searchFilters: {},
      minimizeFlag: true,
      params: null,
      requestParams: {},
      unsubscribe: null,
      perPage: commonHelper.perPageData,
      pageOptions: commonHelper.getPageOptionsList(),
      totalRows: null,
      currentPage: 1,
      routeName: this.$router.currentRoute.name,
      showItcSummaryModal: false,
      scr2ModalFlag1: false,
      scr2ModalFlag2: false
    };
  },
  validations: {
    leName: {
      text: { required }
    },
    // selectedGSTNumber: {
    //   value: { required }
    // },
    selectedPeriodFrom: {
      value: { required }
    },
    selectedPeriodTo: {
      value: { required }
    },
    selectedPeriod: {
      value: { required }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        this.scr2ModalFlag1 = this.$store.state.complainceDashboard.scr2ModalFlag;
        this.scr2ModalFlag2 = this.$store.state.complainceDashboard.scr2Modal2Flag;
        if (
          actionName === 'download' &&
          !this.showOpenGstrTwoModal &&
          !this.showOpenGstrDetailsModal &&
          !this.scr2ModalFlag1 &&
          !this.scr2ModalFlag2
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          if (this.activeTab === 'gstrTwo') {
            const downloadpayload = { ...this.requestParams };
            downloadpayload._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'complainceDashboard/getGstrTwoList',
              'gstr2',
              () => (this.loader = false)
            );
          } else if (this.activeTab === 'gstrTwoB') {
            const downloadpayload = { ...this.requestParams };
            downloadpayload._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'complainceDashboard/getGstrTwoBList',
              'gstr2B',
              () => (this.loader = false)
            );
          } else if (this.activeTab === 'gstrThreeB') {
            const downloadpayload = { ...this.requestParams };
            downloadpayload._limit = 1000;
            this.downloadExcel.downloadData(
              downloadpayload,
              'complainceDashboard/complianceTab3',
              'gstr3B',
              () => (this.loader = false)
            );
          }
        }
      }
    });
  },
  methods: {
    mainSearch() {
      const loadingFlag = this.$store.state.complainceDashboard.loading;
      if (!loadingFlag) {
        this.params = null;
        if (this.activeTab === 'gstrTwo' || this.activeTab === 'gstrTwoB') {
          this.getComplainceGridList();
        } else if (this.activeTab === 'gstrThreeB') {
          this.getComplainceGridLists();
        }
      }
    },
    getComplainceGridList() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      if (
        this.leName.value &&
        this.selectedPeriodFrom.value &&
        this.selectedPeriodTo.value
      ) {
        this.requestParams = {
          _page: this.currentPage - 1,
          _limit: this.perPage
        };
        if (!this.params) {
          this.requestParams = {
            _page: this.currentPage - 1,
            _limit: this.perPage,
            le_id: this.leName.value,
            gstn_no: this.selectedGSTNumber.value,
            period_start_date: this.selectedPeriodFrom.value,
            period_end_date: this.selectedPeriodTo.value,
            itc_elg: this.selectedItcEligible.value,
            reverse_charge: this.selectedReverseCharge.value
          };
        } else {
          this.requestParams = Object.assign(this.requestParams, this.params);
        }
        this.searchParams = {
          ...this.requestParams,
          leName: this.leName.text,
          selectedPeriodFrom: this.selectedPeriodFrom.text,
          selectedPeriodTo: this.selectedPeriodTo.text,
          selectedItcEligible: this.selectedItcEligible.text,
          selectedReverseCharge: this.selectedReverseCharge.text
        };
        this.searchFilters = {
          leName: this.leName,
          selectedPeriodFrom: this.selectedPeriodFrom,
          selectedPeriodTo: this.selectedPeriodTo,
          selectedGSTNumber: this.selectedGSTNumber
        };
      } else {
        alert('Please Select all the mandatory fields.');
      }
      // }
    },
    getComplainceGridLists() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      if (this.leName.value && this.selectedPeriod.value) {
        this.requestParams = {
          _page: this.currentPage - 1,
          _limit: this.perPage
        };
        if (!this.params) {
          this.requestParams = {
            _page: this.currentPage - 1,
            _limit: this.perPage,
            le_id: this.leName.value,
            gstn_no: this.selectedGSTNumber.value,
            period: this.selectedPeriod.value,
            itc_elg: this.selectedItcEligible.value,
            reverse_charge: this.selectedReverseCharge.value
          };
        } else {
          this.requestParams = Object.assign(this.requestParams, this.params);
        }
        this.searchParams = {
          ...this.requestParams,
          leName: this.leName.text,
          selectedPeriod: this.selectedPeriod.text,
          selectedItcEligible: this.selectedItcEligible.text,
          selectedReverseCharge: this.selectedReverseCharge.text
        };
      } else {
        alert('Please Select all the mandatory fields.');
      }
      // }
    },
    selectedTab(activeTab) {
      this.currentPage = 1;
      this.totalRows = 0;
      this.activeTab = activeTab;
      this.showHistoryBtn = false;
      if (activeTab === 'gstrTwo' || activeTab === 'gstrTwoB') {
        this.inprocessActive = true;
        if (
          this.leName.value &&
          this.selectedPeriodFrom &&
          this.selectedPeriodTo
        ) {
          this.requestParams = {
            _page: this.currentPage - 1,
            _limit: this.perPage
          };
          if (!this.params) {
            this.requestParams = {
              _page: this.currentPage - 1,
              _limit: this.perPage,
              le_id: this.leName.value,
              gstn_no: this.selectedGSTNumber.value,
              period_start_date: this.selectedPeriodFrom.value,
              period_end_date: this.selectedPeriodTo.value,
              itc_elg: this.selectedItcEligible.value,
              reverse_charge: this.selectedReverseCharge.value
            };
          } else {
            this.requestParams = Object.assign(this.requestParams, this.params);
          }
          this.searchParams = {
            ...this.requestParams,
            leName: this.leName.text,
            selectedPeriodFrom: this.selectedPeriodFrom.text,
            selectedPeriodTo: this.selectedPeriodTo.text,
            selectedItcEligible: this.selectedItcEligible.text,
            selectedReverseCharge: this.selectedReverseCharge.text
          };
          this.searchFilters = {
            leName: this.leName,
            selectedPeriodFrom: this.selectedPeriodFrom,
            selectedPeriodTo: this.selectedPeriodTo,
            selectedGSTNumber: this.selectedGSTNumber
          };
        } else {
          alert('Please Select Legal Entity, Period From and Period To');
        }
      } else {
        this.inprocessActive = false;
        if (this.leName.value && this.selectedPeriod) {
          this.requestParams = {
            _page: this.currentPage - 1,
            _limit: this.perPage
          };
          if (!this.params) {
            this.requestParams = {
              _page: this.currentPage - 1,
              _limit: this.perPage,
              le_id: this.leName.value,
              gstn_no: this.selectedGSTNumber.value,
              period: this.selectedPeriod.value,
              itc_elg: this.selectedItcEligible.value,
              reverse_charge: this.selectedReverseCharge.value
            };
          } else {
            this.requestParams = Object.assign(this.requestParams, this.params);
          }
          this.searchParams = {
            ...this.requestParams,
            leName: this.leName.text,
            selectedPeriod: this.selectedPeriod.text,
            selectedItcEligible: this.selectedItcEligible.text,
            selectedReverseCharge: this.selectedReverseCharge.text
          };
        } else {
          alert('Please Select Legal Entity and Period');
        }
      }
    },
    totalRowsCount(item) {
      this.totalRows = item;
    },
    openValueSetModal(vsetCode, periodType, valueType) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.periodType = periodType;
      this.valueType = valueType;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD
      ) {
        this.parent_value_set_id = this.leName.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
        this.selectedGSTNumber = this.defaultValue;
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
        this.selectedPeriod = this.defaultValue;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.selectedGSTNumber = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        if (this.valueType === 'itc') {
          this.selectedItcEligible = {
            value: item.value_code,
            text: item.value_meaning
          };
        } else if (this.valueType === 'reverse') {
          this.selectedReverseCharge = {
            value: item.value_code,
            text: item.value_meaning
          };
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (this.periodType === 'periodFrom') {
          this.selectedPeriodFrom = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.periodType === 'periodTo') {
          this.selectedPeriodTo = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.periodType === 'period') {
          this.selectedPeriod = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      }
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, periodType, valueType) {
      if (vsetCode === this.leName.value) {
        this.leName = this.defaultValue;
        this.selectedGSTNumber = this.defaultValue;
        this.selectedPeriodFrom = this.defaultValue;
        this.selectedPeriodTo = this.defaultValue;
        this.selectedPeriod = this.defaultValue;
      } else if (vsetCode === this.selectedGSTNumber.value) {
        this.selectedGSTNumber = this.defaultValue;
      } else if (vsetCode === this.selectedItcEligible.value) {
        this.selectedItcEligible = this.defaultValue;
      } else if (vsetCode === this.selectedReverseCharge.value) {
        this.selectedReverseCharge = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        if (periodType === 'periodFrom') {
          this.selectedPeriodFrom = this.defaultValue;
        } else if (periodType === 'periodTo') {
          this.selectedPeriodTo = this.defaultValue;
        } else if (periodType === 'period') {
          this.selectedPeriod = this.defaultValue;
        }
      } else if (vsetCode === appStrings.VALUESETTYPE.YES_NO) {
        if (valueType === 'itc') {
          this.selectedItcEligible = this.defaultValue;
        } else if (valueType === 'reverse') {
          this.selectedReverseCharge = this.defaultValue;
        }
      }
    },
    clear() {
      this.leName = this.defaultValue;
      this.selectedGSTNumber = this.defaultValue;
      this.selectedPeriodFrom = this.defaultValue;
      this.selectedPeriodTo = this.defaultValue;
      this.selectedPeriod = this.defaultValue;
      this.selectedItcEligible = this.defaultValue;
      this.selectedReverseCharge = this.selectedReverseCharge;
      this.totalRows = null;
      this.searchParams = null;
      this.requestParams = {};
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    showHideItcSummaryModal(flag) {
      this.showItcSummaryModal = flag;
    },
    pageChange() {
      this.mainSearch();
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
